const calculatLetterGrade = (grade: any, gradeConfig: any, company: any) => {
  if (
    grade === undefined ||
    gradeConfig === undefined ||
    company === undefined
  ) {
    return "-";
  }
  const config = gradeConfig;
  if (isRisk(company)) {
    const cnfg = config.find((c: any) => c.type === "risk");
    const ranges = cnfg.ranges;
    const penalty = cnfg.penalty;
    grade = grade - penalty < 0 ? 0 : grade - penalty;
    return ranges.find((r: any) => r.min <= grade && r.max >= grade).grade;
  } else if (isIncident(company)) {
    const cnfg = config.find((c: any) => c.type === "incident");
    const ranges = cnfg.ranges;
    const penalty = cnfg.penalty;
    grade = grade - penalty < 0 ? 0 : grade - penalty;
    return ranges.find((r: any) => r.min <= grade && r.max >= grade).grade;
  } else {
    const cnfg = config.find((c: any) => c.type === "none");
    const ranges = cnfg.ranges;
    const penalty = cnfg.penalty;
    grade = grade - penalty < 0 ? 0 : grade - penalty;
    return ranges.find((r: any) => r.min <= grade && r.max >= grade).grade;
  }
};
const isRisk = (company: any) => {
  return company.eventsFlag === "Critical" ? true : false;
};

const isIncident = (company: any) => {
  return company?.environmental?.incidents?.length > 0 ||
    company?.social?.incidents?.length > 0 ||
    company?.governance?.incidents?.length > 0
    ? true
    : false;
};

export default calculatLetterGrade;
