import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import {
  AuthProvider,
  CompaniesProvider,
  CompanyProvider,
  FiltersProvider,
  FundsProvider,
  LandingPageProvider,
} from "context";
import { useSelectedLanguage } from "language";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LandingPage, ReportScreen, SignInScreen } from "screens";
import App, { RequireAuth, RequireNotAuth } from "./App";
import client from "./apollo";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./style";
const rootElement = document.getElementById("root");

const Root = () => {
  const { selectedDirection } = useSelectedLanguage();
  const generatedTheme = theme(selectedDirection);
  rootElement!.style.direction = selectedDirection;
  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <AuthProvider>
          <LandingPageProvider>
            <FiltersProvider>
              <FundsProvider>
                <CompaniesProvider>
                  <CompanyProvider>
                    <ThemeProvider theme={generatedTheme}>
                      <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <Routes>
                          <Route path="/" element={<App />}>
                            <Route
                              index
                              element={
                                <RequireAuth>
                                  <LandingPage />
                                </RequireAuth>
                              }
                            />
                            <Route
                              path="report/:id"
                              element={
                                <RequireAuth>
                                  <ReportScreen />
                                </RequireAuth>
                              }
                            />
                            <Route
                              path="signin"
                              element={
                                <RequireNotAuth>
                                  <SignInScreen />
                                </RequireNotAuth>
                              }
                            />
                            <Route
                              path="*"
                              element={
                                <main style={{ padding: "1rem" }}>
                                  <p>There's nothing here!</p>
                                </main>
                              }
                            />
                          </Route>
                        </Routes>
                      </BrowserRouter>
                    </ThemeProvider>
                  </CompanyProvider>
                </CompaniesProvider>
              </FundsProvider>
            </FiltersProvider>
          </LandingPageProvider>
        </AuthProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
};

render(<Root></Root>, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
