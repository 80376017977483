import MuiCard from "@mui/material/Card";
import styled from "styled-components";

export const Card = styled(MuiCard)`
  && {
    min-width: 30px;
    border-radius: 10px;
    box-shadow: 0px 1.38px 6.9px 0px #e2e5f6;
    border: 0;
    height: 100%;
  }
`;
