import CardContent from "@mui/material/CardContent";
import MuiCard from '@mui/material/Card';
import styled from "styled-components";

export const CompanyCard = styled(CardContent)`
  && {
    max-width: 100%;
    padding: 0;
  }
`
export const Signin = styled(MuiCard)`
  && {
    background-color: #E4EDF7;
    width: 33vw;
    text-align: center;
    padding: 2.8rem 1.8rem;
  }
`

export const Scroll = styled.div`
  margin: 1rem 0;
  && ::-webkit-scrollbar-thumb {
    background: #888;
  }
  && ::-webkit-scrollbar-track {
    background: #E2E5F6;
  }
`;

export const Card = styled(MuiCard)`
  && {
    min-width: 30px;
    border-radius: 10px;
    box-shadow: 0px 1.38px 6.9px 0px #E2E5F6;
    border: 0;
    height: 100%;
  }
`;

export const GraphContainer = styled.div`
  && {
    width: 10rem;
    height: 10rem;
    display: flex;
    position: relative;
    padding-top: 1rem;
    margin: 1rem;
  }
`;

export const Graph = styled.div`
  && {
    width: 10rem;
    height: 10rem;
    display: flex;
    position: relative;
    margin: 2rem 1rem .2rem .2rem;
  }
`;

export const GraphColumn = styled.div`
  && {
    width: 2rem;
    height: 12rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-basis: space-around;
  }
`;

export const GraphBox = styled.div`
  && {
    height: 2rem;
    width: 2rem;
    padding: 0;
  }
  `;
  
  export const GraphBox1 = styled(GraphBox)`
  && {
    background-color: #AFE4CB;
    border: .5px solid white;
  }
  `;
  
  export const GraphBox2 = styled(GraphBox)`
  && {
    border: .5px solid white;
    background-color: #D7ECC0;
    border: .5px solid white;
  }
  `;
  
  export const GraphBox3 = styled(GraphBox)`
  && {
    background-color: #EBF0BB;
    border: .5px solid white;
  }
  `;
  
  export const GraphBox4 = styled(GraphBox)`
  && {
    background-color: #FFF3B5;
    border: .5px solid white;
  }
  `;
  
  export const GraphBox5 = styled(GraphBox)`
  && {
    background-color: #FFCF99;
    border: .5px solid white;
  }
  `;
  
  export const GraphBox6 = styled(GraphBox)`
  && {
    background-color: #FFAB7C;
    border: .5px solid white;
  }
  `;
  
  export const GraphBox7 = styled(GraphBox)`
  && {
    background-color: #FF6443;
    border: .5px solid white;
  }
`;

export const Circle = styled.div`
  && {
    height: 1rem;
    width: 1rem;
    padding: 0;
    background-color: #1BA6C6;
    border-radius: 100%;
    border: 1px solid #196275;
    position: absolute;
  }
`;
