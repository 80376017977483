import Box from "@mui/material/Box";
import CircularProgress, {
	circularProgressClasses,
} from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface Props {
  progress: number;
  itemSize: number;
  color?: string;
}

const Circular = (props: Props) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={props.itemSize}
        thickness={3}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            props.color
              ? props.color
              : theme.palette.mode === "light"
              ? "primary.main"
              : "primary.main",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={props.itemSize}
        thickness={3}
        value={Math.max(props.progress, 0)}
      />
      <Grid
        sx={{
          top: props.itemSize / 4,
          position: "absolute",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          fontSize={props.itemSize > 80 ? "2rem" : "1.25rem"}
          color={props.color ? props.color : "primary.main"}
          textAlign="center"
        >
          {props.progress < 0 ? "-" : props.progress}
        </Typography>

        <Divider variant="middle" />
        <Typography
          variant={props.itemSize > 80 ? "h5" : "subtitle2"}
          color={props.color ? props.color : "primary.main"}
          textAlign="center"
        >
          100
        </Typography>
      </Grid>
    </Box>
  );
};

export default Circular;
