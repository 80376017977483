import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AuthContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as SC from "./style";

const SigninCard = () => {
  const navigate = useNavigate();
  const { login, loginDetails, updateLoginDetails } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleEmailChange = (event: any) => {
    updateLoginDetails({ identifier: event.target.value });
  };

  const handlePasswordChange = (event: any) => {
    updateLoginDetails({ password: event.target.value });
  };
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();
  const handleLogin = async () => {
    const response = await login();
    if (response.success) {
      navigate("/");
    } else {
      setOpen(true);
      setMessage(response.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SC.Signin>
      <Stack
        component="form"
        spacing={3}
        autoComplete="off"
        justifyContent="space-evenly"
        alignItems="center"
        onKeyPress={(event: any) => event.key === "Enter" && handleLogin()}
      >
        <Typography variant="h3">
          {(selectedDictionary as any).login}
        </Typography>
        <TextField
          id="outlined-name"
          value={loginDetails.identifier}
          type="email"
          placeholder={(selectedDictionary as any).email}
          variant="outlined"
          onChange={handleEmailChange}
          required
          fullWidth
          size="small"
        />
        <TextField
          id="outlined-password"
          value={loginDetails.password}
          type="password"
          placeholder={(selectedDictionary as any).password}
          variant="outlined"
          onChange={handlePasswordChange}
          required
          fullWidth
          size="small"
        />
        <Box textAlign="center" width="50%">
          <Link to="#" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              disableElevation
              fullWidth
              onClick={handleLogin}
              disableRipple
              size="large"
              sx={{
                padding: "0.8rem 1rem",
                borderRadius: "7px",
                fontSize: "1.2rem",
              }}
            >
              <Typography variant="h3">
                {(selectedDictionary as any).enter}
              </Typography>
            </Button>
          </Link>
        </Box>
        {/* <Box textAlign='center'>
          <Link to="#" style={{ textDecoration: 'none' }}>
            <MuiButton
              disableElevation
              disableRipple
            >
              שכחתי סיסמא
            </MuiButton>
          </Link>
        </Box> */}
      </Stack>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClick={handleClose}
          severity="warning"
          icon={
            <CancelIcon
              fontSize="inherit"
              sx={{ margin: "auto -15px auto 2px" }}
            />
          }
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </SC.Signin>
  );
};

export default SigninCard;
