import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "context";
import { useSelectedLanguage } from "language";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Toggle } from ".";
import * as SC from "./style";

export default function BasicMenu(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userData, logout } = React.useContext(AuthContext);
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signout = () => {
    logout();
    navigate("/signin");
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {(selectedDictionary as any).options}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Toggle />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <SC.StyledButton size="medium" onClick={signout}>
            {(selectedDictionary as any).exit}
          </SC.StyledButton>
        </MenuItem>
      </Menu>
    </div>
  );
}
