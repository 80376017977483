import { useLazyQuery, useMutation } from "@apollo/client";
import { LOGIN } from "graphql/mutation";
import { ME } from "graphql/query";
import * as LS from "local-storage";
import React, { createContext, useEffect, useState } from "react";
import { AuthStatus, LoginDetails, LoginResult, UserData } from "types";

interface AuthContextState {
  authStatus: AuthStatus;
  updateLoginDetails: (i: Partial<LoginDetails>) => void;
  loginDetails: LoginDetails;
  login: () => Promise<LoginResult>;
  logout: () => void;
  getMe: () => Promise<any>;
  userData: {
    id: string;
    username: string;
    role: string | null;
    letterGrading: boolean;
    hasFundsFeature: boolean;
    orgId: number | null;
    hasCompanyGraph: boolean;
    hasClimateGrade: boolean;
    selectedSearchOption: string | null;
  };
  setUserData: (userData: any) => void;
  meLoading: boolean;
}

export const AuthContext = createContext({} as AuthContextState);

export const AuthProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const userJWT = LS.getJwt();
  const [getMe, { data: meData, loading: meLoading }] = useLazyQuery(ME, {
    context: {
      headers: {
        authorization: userJWT,
      },
    },
  });
  const [loginMutation] = useMutation(LOGIN, {
    refetchQueries: [{ query: ME }],
  });
  const [userData, setUserData] = useState({
    id: "",
    username: "",
    role: "",
    letterGrading: false,
    hasFundsFeature: false,
    orgId: null,
    hasCompanyGraph: false,
    hasClimateGrade: false,
    selectedSearchOption: null,
  } as UserData);
  const [authStatus, setAuthStatus] = useState<AuthStatus>(
    AuthStatus.SignedOut
  );
  const [loginDetails, setLoginDetails] = useState<LoginDetails>({
    identifier: "",
    password: "",
  } as LoginDetails);

  useEffect(() => {
    if (meData?.me?.id) {
      setUserData({
        id: meData?.me?.id,
        username: meData?.me?.username,
        role: meData?.me?.role?.name,
        letterGrading: meData?.me?.letterGrading,
        hasFundsFeature: meData?.me?.hasFundsFeature,
        orgId: meData?.me?.orgId,
        hasCompanyGraph: meData?.me?.hasCompanyGraph,
        hasClimateGrade: meData?.me?.hasClimateGrade,
        selectedSearchOption: meData?.me?.selectedSearchOption,
      });
      setAuthStatus(AuthStatus.SignedIn);
    }
    if (!userData.role) {
      getMe();
    }
  }, [meData]);

  const updateLoginDetails = (i: Partial<LoginDetails>) =>
    setLoginDetails((pv: LoginDetails) => ({ ...loginDetails, ...i }));

  const login = async (): Promise<LoginResult> => {
    try {
      const response = await loginMutation({
        variables: {
          input: {
            identifier: loginDetails.identifier,
            password: loginDetails.password,
            provider: "local",
          },
        },
      });

      if (response?.data?.login) {
        setAuthStatus(AuthStatus.SignedIn);
        const jwt = response.data.login.jwt;
        const id = response.data.login.user.id;
        const username = response.data.login.user.username;
        const letterGrading = response.data.login.letterGrading;
        const hasFundsFeature = response.data.login.hasFundsFeature;
        const hasCompanyGraph = response.data.login.hasCompanyGraph;
        const hasClimateGrade = response.data.login.hasClimateGrade;
        const orgId = response.data.login.orgId;
        setUserData({
          ...userData,
          id,
          username,
          letterGrading,
          hasFundsFeature,
          hasClimateGrade,
          hasCompanyGraph,
          orgId,
        });

        LS.saveJwt(jwt);
        await getMe();

        return { success: true, message: "" };
      } else {
        return { success: false, message: "שגיאת מערכת" };
      }
    } catch (error: any) {
      return {
        success: false,
        message: "אחד מפרטי ההתחברות שגויים",
      } as LoginResult;
    }
  };

  const logout = () => {
    LS.removeJwt();
    setAuthStatus(AuthStatus.SignedOut);
  };

  const iState = {
    authStatus,
    updateLoginDetails,
    loginDetails,
    login,
    userData,
    setUserData,
    meLoading,
    getMe,
    logout,
  } as AuthContextState;

  return <AuthContext.Provider value={iState}>{children}</AuthContext.Provider>;
};
