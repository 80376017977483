import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card } from "components";
import { AuthContext, CompaniesContext, FiltersContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useEffect } from "react";
import { AuthStatus, LoadingProps } from "types";
import * as SC from "./style";

const CompaniesScreen = () => {
  const matchesMd = useMediaQuery("(min-width:800px)");
  const matchesLg = useMediaQuery("(min-width:1200px)");
  let columns = 1;
  if (matchesLg) {
    columns = 3;
  } else if (matchesMd) {
    columns = 2;
  }

  const loadingProps: LoadingProps = {
    filter: "true",
  };
  const {
    companies,
    getCompanies,
    companiesCount,
    loadingCompanies,
    loadingMoreCompanies,
    loadingCsvCompanies,
    page,
    setPage,
  } = useContext(CompaniesContext);
  const {
    getIndices,
    getSectors,
    indices,
    sectors,
    loadingIndices,
    loadingSectors,
    loadingFilters,
  } = useContext(FiltersContext);
  const { userData, authStatus, getMe } = useContext(AuthContext);
  const { selectedLanguage, selectedDictionary, selectedDirection } =
    useSelectedLanguage();

  const fetchPage = async () => {
    const newPage = page + 1;
    setPage(newPage);
  };

  useEffect(() => {
    if (authStatus === AuthStatus.SignedIn && userData?.id && !userData?.role) {
      getMe();
    }
  }, [getMe]);

  useEffect(() => {
    if (
      authStatus === AuthStatus.SignedIn &&
      userData?.id &&
      !loadingCompanies &&
      (!companies || companies?.length === 0)
    )
      getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    if (!loadingIndices && (!indices || indices?.length === 0)) {
      getIndices();
    }
  }, [getIndices]);

  useEffect(() => {
    if (!loadingSectors && (!sectors || sectors?.length === 0)) {
      getSectors();
    }
  }, [getSectors]);

  const every_nth = (arr: any[], nth: number) =>
    arr.filter((_, i: number) => i % nth === nth - nth);

  // Provide Loading modal with exact page height.
  const appbarDiv = document.querySelector(".greeneye-appbar");
  const pageWrapperStyle =
    loadingCompanies || loadingFilters
      ? {
          height:
            appbarDiv != null && appbarDiv.clientHeight > 0
              ? window.innerHeight - appbarDiv.clientHeight + "px"
              : "90vh",
          overflow: "hidden",
        }
      : {};

  return (
    <>
      <SC.PageWrapper
        selectedDirection={selectedDirection}
        style={pageWrapperStyle}
      >
       
        <SC.Box selectedDirection={selectedDirection}>
          <SC.Column>
            {companies
              ? every_nth(companies, columns).map((c, i) => {
                  return <Card.CollapsedCard {...c} key={i} />;
                })
              : null}
          </SC.Column>
          {matchesMd && (
            <SC.Column>
              {companies
                ? every_nth(companies.slice(1), columns).map((c, i) => {
                    return <Card.CollapsedCard {...c} key={i} />;
                  })
                : null}
            </SC.Column>
          )}
          {matchesLg && (
            <SC.Column>
              {companies
                ? every_nth(companies.slice(2), columns).map((c, i) => {
                    return <Card.CollapsedCard {...c} key={i} />;
                  })
                : null}
            </SC.Column>
          )}
        </SC.Box>
        {companies.length < companiesCount && !loadingCompanies && (
          <Box textAlign="center" p="2rem" pt="0">
            <LoadingButton
              onClick={fetchPage}
              variant="contained"
              size="large"
              disableElevation
              disableRipple
              loading={loadingMoreCompanies}
              loadingIndicator=""
            >
              <Typography variant="h5" color="white">
                {loadingMoreCompanies
                  ? (selectedDictionary as any).loading
                  : (selectedDictionary as any).additionalCompanies}
              </Typography>
            </LoadingButton>
          </Box>
        )}
        {companiesCount === 0 && !loadingCompanies && (
          <Box textAlign="center" p="2rem" pt="0">
            <Typography variant="h4" sx={{ m: "0 auto" }} color="primary">
              {(selectedDictionary as any).noResult}
            </Typography>
          </Box>
        )}
      </SC.PageWrapper>
    </>
  );
};

export default CompaniesScreen;
