import { Box, Divider, List, ListItemText, Typography } from "@mui/material";
import { getEventFlagText } from "components";
import { AuthContext, CompaniesContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCompaniesFilters } from "stores";
import { Company, CompanyLine, DrawerInputTypes } from "types";
import { isCompaniesScreen } from "utils";
import CollapseFilter from "./collapsibleFilters";
import FilterDrawerFooter from "./drawerFooter";
import SliderFilter from "./sliderFilter";
import * as SC from "./style";

const FilterDrawer = () => {
  const {
    clearFilters,
    filters,
    setQueryFilters,
    setSearchTerm,
    loadingFilters,
    queryFilters,
  } = useCompaniesFilters();
  const [toggleRerenderMenu, setToggleRerenderMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [showCsvDownloader, setShowCsvDownloader] = useState(false);
  const {
    selectedLanguage,
    selectedDictionary,
    selectedTypeTitles,
    selectedCsvHeaders,
    selectedDirection,
  } = useSelectedLanguage();

  let [headers, setHeaders] = useState([...selectedCsvHeaders]);

  useEffect(() => {
    const newHeaders = [...headers];
    setHeaders(newHeaders);

    return () => {
      setHeaders([...selectedCsvHeaders]);
    };
  }, []);

  useEffect(() => {
    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, 100);
  }, [toggleRerenderMenu]);

  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const originalOptions =
      filters.find((f) => f.id === "indexes")?.options || [];
    if (searchText?.length > 0) {
      setOptions(
        originalOptions?.filter((option: any) =>
          option.text.toLowerCase().includes(searchText.toLowerCase())
        ) as any
      );
    } else {
      setOptions(originalOptions as any);
    }
  }, [searchText, filters]);

  const location = useLocation();

  const isOpen = isCompaniesScreen(location.pathname);
  const {
    companiesCount,
    setPage,
    getCsvCompanies,
    csvCompanies,
    loadingCsvCompanies,
  } = useContext(CompaniesContext);
  const { userData } = useContext(AuthContext);

  const createCSVData = (companies: Company[]) => {
    let csvData: CompanyLine[] = [];
    if (!companies.length) return csvData;
    for (const company of companies) {
      if (company.securities) {
        for (const security of company.securities) {
          const d: CompanyLine = {
            security: security,
            issuerId: company?.issuerId,
            name: selectedLanguage === "en" ? company.name_en : company.name,
            ticker: company?.ticker,
            isin: company?.isin,
            sector: company?.primarySector?.name,
            esgScore: company?.esgGrade?.grade,
            relativeEsgScore: company?.esgGrade?.gradeRelativeToSectorAverage
              ? selectedTypeTitles[
                  company?.esgGrade?.gradeRelativeToSectorAverage
                ]
              : "",
            percentileInSector: company?.esgGrade?.percentileInSector,
            percentileInGeneral: company?.esgGrade?.percentileInGeneral,
            envScore: company?.environmental?.grade,
            relativeEnvScore: company?.environmental
              ?.gradeRelativeToSectorAverage
              ? selectedTypeTitles[
                  company?.environmental?.gradeRelativeToSectorAverage
                ]
              : "",
            socialScore: company?.social?.grade,
            relativeSocialScore: company?.social?.gradeRelativeToSectorAverage
              ? selectedTypeTitles[
                  company?.social?.gradeRelativeToSectorAverage
                ]
              : "",
            govScore: company?.governance?.grade,
            relativeGovScore: company?.governance?.gradeRelativeToSectorAverage
              ? selectedTypeTitles[
                  company?.governance?.gradeRelativeToSectorAverage
                ]
              : "",
            events: company?.events
              ?.map((e) => e.description?.replaceAll(/"/g, "'"))
              ?.join(";\n")
              ?.replaceAll(/,/g, ","),
            eventsFlag: getEventFlagText(company),
            accGradesWithoutFactor:
              company?.esgGrade?.grade &&
              company?.esgGrade?.accGradesWithoutFactor &&
              company?.esgGrade?.grade -
                company?.esgGrade?.accGradesWithoutFactor,
            badActivities: company?.badActivities
              ?.map((a) => selectedTypeTitles[a.type])
              .join("; "),
            esgActivities: company?.sdgActivities
              ?.map((a) => selectedTypeTitles[a.type])
              .join("; "),
          };
          csvData.push(d);
        }
      }
    }
    return csvData;
  };

  const handleToggleCsvDownloader = () => {
    setShowCsvDownloader((prevState) => !prevState);
  };

  const handleClearFilters = () => {
    clearFilters();
    setPage(0);
    setQueryFilters({});
    setSearchTerm("");
    setToggleRerenderMenu(!toggleRerenderMenu);
  };

  const loadingProps: any = {
    filter: "true",
  };
  const filtersDiv = document.querySelector(".greeneye-filters");
  const pageWrapperStyle = loadingFilters
    ? {
        height:
          filtersDiv != null && filtersDiv.clientHeight > 0
            ? window.innerHeight - filtersDiv.clientHeight + "px"
            : "90vh",
        overflow: "hidden",
      }
    : {};

  return (
    <div
      style={{
        background: "#eff2f5",
        position: "sticky",
        top: "0px",
      }}
    >
      <SC.HeaderListLine>
        <ListItemText primary={(selectedDictionary as any).filters.filters} />
      </SC.HeaderListLine>
      <Box
        textAlign="center"
        p=".5rem"
        color="primary"
        sx={{ background: "white" }}
      >
        <Typography
          variant="h6"
          color="primary"
          onClick={handleClearFilters}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {!rerender
            ? (selectedDictionary as any).filters.clearAll
            : "מנקה...."}
        </Typography>
      </Box>
      <List
        // style={pageWrapperStyle}
        className="greeneye-filters"
        sx={{
          width: "100%",
          maxWidth: 360,
          minHeight: "23rem",
          bgcolor: "background.paper",
          overflowY: "auto",
          direction: "ltr",
        }}
      >
        {!rerender &&
          filters?.map((item: any, i: number) => {
            return (
              <Box
                width="100%"
                role="presentation"
                key={i}
                sx={
                  {
                    // direction:
                    //   selectedDirection === Direction.LTR ? "ltr" : "rtl",
                  }
                }
              >
                {item?.type === DrawerInputTypes.Radio ||
                item?.type === DrawerInputTypes.Checkbox ? (
                  <CollapseFilter
                    {...{
                      ...item,
                      ...(item.id === "indexes" && { options: options }),
                      handleChange: handleChange,
                      searchText: searchText,
                    }}
                    type={
                      item?.type === DrawerInputTypes.Checkbox
                        ? "CheckBox"
                        : item?.type === DrawerInputTypes.Radio
                        ? "Radio"
                        : "Slider"
                    }
                  />
                ) : (
                  <SliderFilter {...item} />
                )}
                {i < item?.length - 1}
                {i < filters?.length - 1 && <Divider variant="middle" />}
              </Box>
            );
          })}
      </List>
      <Divider />
      <FilterDrawerFooter {...queryFilters} />
    </div>
  );
};

export default FilterDrawer;
