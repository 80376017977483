import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from "react";
import { AuthContext } from "context";
import { AuthStatus } from "types";

export const useRedirectToHomeIfAuthed = () => {
    const navigate = useNavigate();
    const { authStatus, meLoading } = useContext(AuthContext);

    useEffect(() => {
        if(!meLoading && authStatus === AuthStatus.SignedIn){
            navigate("/");
        }
    }, [authStatus, meLoading, navigate])
}

export const useRedirectToSignInIfNotAuthed = () => {
    const navigate = useNavigate();
    const { authStatus, meLoading } = useContext(AuthContext);

    useEffect(() => {
        if(!meLoading && authStatus !== AuthStatus.SignedIn){
            navigate("/signin");
        }
    }, [authStatus, meLoading, navigate])
}

