import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { useSelectedLanguage } from "language";
import { Fragment, useState } from "react";
import { useCompaniesFilters } from "stores";
import { FilterItem } from "types";
import * as SC from "./style";

function valuetext(value: any) {
  return `${value}`;
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 100,
    label: "100",
  },
];

const RTLSlider = styled(Slider)({
  // "& .MuiSlider-mark": {
  //   height: 0,
  //   width: 0,
  // },
  // "& .MuiSlider-markLabel": {
  //   transform: "translateX(50%)",
  //   fontSize: 14,
  // },
  // "& .MuiSlider-thumb": {
  //   transform: "translate(50%, -50%)",
  // },
  // "& .MuiSlider-track": {
  //   backgroundColor: "#8ED0DF",
  //   border: "none",
  // },
});

const SliderFilter = ({ ...props }) => {
  const { filters, setFilters } = useCompaniesFilters();
  const [open, setOpen] = useState(false);
  const [sliderValues, setSliderValues] = useState([0, 100]);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleChangeCommitted = (e: any, value: number | number[]) => {
    value = Array.isArray(value) ? value : [value];
    const newState = filters?.map((s: FilterItem) =>
      s.id === props?.id
        ? {
            title: props.title,
            values: (value as Array<number>).map((v: number) => ({
              text: v.toString(),
              value: v,
            })),
            id: s.id,
            options: s.options,
            type: s.type,
          }
        : s
    );
    setFilters(newState);
  };

  const handleChange = (event: Event, value: number | number[]) => {
    value = Array.isArray(value) ? value : [value];
    setSliderValues(value);
  };

  const setMinMax = (min: number, max: number) => {
    setSliderValues([min, max]);
    handleChangeCommitted(null, [min, max]);
  };

  const { selectedLanguage, selectedDictionary, selectedDirection } =
    useSelectedLanguage();

  const sliderTheme = createTheme({
    palette: {
      primary: {
        main: "#1D8EA8",
        light: "#1BA6C6",
      },
      text: {
        primary: "#202020",
        secondary: "#727272",
      },
      grey: {
        50: "#E5EFFF",
        100: "#FBFBFB",
        200: "#E5E5E5",
        900: "#2E313A",
      },
    },
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(),
    },
    shape: {
      borderRadius: 7,
    },
  });

  return (
    <Fragment>
      <ListItemButton onClick={handleClick}>
        <SC.ListLine selectedDirection={selectedDirection}>
          <ListItemText secondary={props.title} sx={{ fontWeight: "500" }} />
          {open ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </SC.ListLine>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ padding: ".5rem 3rem" }}>
          <ThemeProvider theme={sliderTheme}>
            <RTLSlider
              getAriaLabel={() => "Grade range"}
              value={sliderValues}
              marks={marks}
              onChangeCommitted={handleChangeCommitted}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
            />
          </ThemeProvider>
          <Grid container justifyContent="space-between">
            <TextField
              type="number"
              size="small"
              variant="standard"
              margin="dense"
              inputProps={{ min: 0, max: sliderValues[1] }}
              value={sliderValues[0]}
              label={(selectedDictionary as any).min}
              onChange={(e) => {
                if (!e.target.value) return;
                var value = parseInt(e.target.value, 10);
                if (value > sliderValues[1]) return;
                if (value < 0) value = 0;
                setMinMax(value, sliderValues[1]);
              }}
            />
            <TextField
              type="number"
              size="small"
              variant="standard"
              margin="dense"
              inputProps={{ min: sliderValues[0], max: 100 }}
              value={sliderValues[1]}
              label={(selectedDictionary as any).max}
              onChange={(e) => {
                if (!e.target.value) return;
                var value = parseInt(e.target.value, 10);
                if (value > 100) value = 100;
                setMinMax(sliderValues[0], value);
              }}
            />
          </Grid>
        </Box>
      </Collapse>
    </Fragment>
  );
};

export default SliderFilter;
