import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ProgressGraph } from "components/Progress";
import { useSelectedLanguage } from "language";
import { usePrintStore } from "stores";
import * as SC from "./style";

interface Props {
  name?: string;
  grade?: number;
  min?: number;
  max?: number;
  average?: number;
}

const ProgressCard = (props: Props) => {
  const { printing } = usePrintStore();
  const { name, grade, min, max, average } = props;
  const matchesSm = useMediaQuery("(min-width:600px)");
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography
          variant="h4"
          style={{ textAlign: "center" }}
          color="text.primary"
          gutterBottom
        >
          {name || "-"}
        </Typography>
        <div style={{ textAlign: "center", paddingTop: "1rem" }}>
          <div>
            <ProgressGraph
              progress={grade || grade === 0 ? grade : -1}
              itemSize={printing || !matchesSm ? 60 : 80}
            />
          </div>
          <div
            style={{
              display: "grid",
              paddingTop: "1rem",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <div>
              <Typography variant="subtitle1">
                {(selectedDictionary as any).progressCell1}
              </Typography>
              <Typography variant="h3" color="primary.main">
                {min?.toString() || "-"}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1">
                {(selectedDictionary as any).progressCell2}
              </Typography>
              <Typography variant="h3" color="primary.main">
                {average?.toString() || "-"}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1">
                {(selectedDictionary as any).progressCell3}
              </Typography>
              <Typography variant="h3" color="primary.main">
                {max?.toString() || "-"}
              </Typography>
            </div>
          </div>
        </div>
      </CardContent>
    </SC.Card>
  );
};

export default ProgressCard;
