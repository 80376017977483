import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  name: string
  color?: string
  text?: string
  size: string
  customHeight?: string
  customWidth?: string
  default?: string
}

const MyIcon = (props: Props) => {
  return (
    <Box sx={{ alignItems: "center" }}>
      <div style={{
        backgroundColor: props.color || "",
        width: props.customWidth ? props.customWidth : props.size,
        height: props.customHeight ? props.customHeight : props.size,
        borderRadius: "100%",
        margin: "auto"
      }}>
        {props.name && <img
          style={{
            width: props.size,
            height: props.customHeight ? props.customHeight : props.size,
          }}
          src={require(`../assets/${props.name || props.default}.svg`)}
          alt={props.name}
        />}
      </div>
      {props.text && <Typography textAlign="center" variant="h6">
        {props.text}
      </Typography>}
    </Box>
  )
};

export default MyIcon;