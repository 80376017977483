import { TypeTitles, TypeTitlesEn, csvHeaders, csvHeadersEn } from "types";
import en from "./en";
import he from "./he";

enum Direction {
  RTL = "rtl",
  LTR = "ltr",
}

export default {
  en: {
    language: en,
    lString: "en",
    direction: Direction.LTR,
    csvHeaders: csvHeadersEn,
    typeTitles: TypeTitlesEn,
  },
  he: {
    language: he,
    lString: "he",
    direction: Direction.RTL,
    csvHeaders: csvHeaders,
    typeTitles: TypeTitles,
  },
};
