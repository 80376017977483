import { useLazyQuery } from "@apollo/client";
import { formatIssues } from "formatters";
import { INDICES, ISSUES, SECTORS } from "graphql/query";
import { useSelectedLanguage } from "language";
import React, { createContext, useContext, useEffect, useState } from "react";
import { IncidentsFilter, IssueEntity, TextValuePair } from "types";
import { AuthContext } from "./auth";

interface FiltersContextState {
  error: any;
  indices: TextValuePair<string>[];
  issues: IssueEntity[];
  incidents: TextValuePair<string>[];
  loadingIndices: boolean;
  loadingIssues: boolean;
  loadingSectors: boolean;
  sectors: TextValuePair<string>[];
  getIssues: () => Promise<any>;
  getIndices: () => Promise<any>;
  getSectors: () => Promise<any>;
  loadingFilters: boolean;
}

export const FiltersContext = createContext({} as FiltersContextState);

export const FiltersProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { userData } = useContext(AuthContext);

  const [loadingFilters, setLoadingFilters] = useState(false);

  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  const sectorFilters =
    userData?.orgId != null
      ? [
          {
            primaryCompanies: {
              indexes: { organizations: { id: { eq: userData?.orgId } } },
            },
          },
        ]
      : [
          //   { primaryCompanies: { users: { id: { eq: userData?.id } } } },
          {
            primaryCompanies: {
              indexes: { users: { id: { eq: userData?.id } } },
            },
          },
        ];

  const incidentsOptions = [
    {
      text: (selectedDictionary as any).incidents,
      value: IncidentsFilter.INCIDENTS_FLAG_NONE,
    },
    {
      text: (selectedDictionary as any).alerts,
      value: IncidentsFilter.INCIDENTS_FLAG_HIGH,
    },
    {
      text: (selectedDictionary as any).redFlagAlerts,
      value: IncidentsFilter.INCIDENTS_FLAG_CRITICAL,
    },
    {
      text: (selectedDictionary as any).noIncidents,
      value: IncidentsFilter.NO_INCIDENTS_FLAG_NONE,
    },
  ];
  const indicesFilters =
    userData?.orgId != null
      ? [
          {
            organizations: {
              id: { eq: userData?.orgId },
            },
          },
          {
            companies: {
              organizations: {
                id: { eq: userData?.orgId },
              },
            },
          },
        ]
      : [
          { users: { id: { eq: userData?.id } } },
          { companies: { users: { id: { eq: userData?.id } } } },
        ];
  const [
    getIndices,
    { data: indices, loading: loadingIndices, error: indicesError },
  ] = useLazyQuery(INDICES, {
    variables: {
      filters: {
        or: indicesFilters,
      },
    },
  });

  const [
    getIssues,
    { data: issues, loading: loadingIssues, error: issuesError },
  ] = useLazyQuery(ISSUES);

  const [
    getSectors,
    { data: sectors, loading: loadingSectors, error: sectorsError },
  ] = useLazyQuery(SECTORS, {
    variables: {
      filters: {
        or: sectorFilters,
      },
    },
  });

  useEffect(() => {
    setLoadingFilters(loadingIndices || loadingIssues || loadingSectors);
  }, [loadingIndices, loadingIssues, loadingSectors]);

  const iState = {
    error: issuesError && sectorsError && indicesError,
    indices:
      indices?.indices?.data?.map(
        (d: any): TextValuePair<string> => ({
          text: d.attributes.name,
          value: d.id,
        })
      ) || [],
    incidents: incidentsOptions,
    issues: formatIssues(issues?.issues?.data) || [],
    loadingIndices,
    loadingIssues,
    loadingSectors,
    sectors:
      sectors?.sectors?.data?.map(
        (d: any): TextValuePair<string> => ({
          text: d.attributes.name,
          value: d.id,
        })
      ) || [],
    getIssues,
    getIndices,
    getSectors,
    loadingFilters,
  } as FiltersContextState;

  return (
    <FiltersContext.Provider value={iState}>{children}</FiltersContext.Provider>
  );
};
