import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Slider, { SliderThumb, SliderValueLabel } from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Progress } from "components";
import { Direction, useSelectedLanguage } from "language";
import { usePrintStore } from "stores";
import * as SC from "./style";

interface Props {
  letterGrade: string;
  grade: number;
  average: number;
  min: number;
  max: number;
  comment: string;
}

interface Mark {
  value: number;
  label: string;
}

const ThumbGradeComponent = styled(SliderThumb)({
  height: 30,
  width: 30,
  backgroundColor: "#1D8EA8",
});

const MarkGradeComponent = styled(SliderValueLabel)<any>`
  fontSize: 14,
  color: "white",
  fontWeight: "normal",
  background: "none",
  "&.MuiSlider-valueLabelOpen": {
    transform: ${(props) =>
      props.selectedDirection === Direction.LTR
        ? "scaleX-1)"
        : "translateY(38%) scale(1) scaleX(-1)"},
},
`;

const ThumbAvgComponent = styled(SliderThumb)({
  height: 25,
  width: 25,
  border: "none",
  backgroundColor: "#8ED0DF",
  "&:before": {
    boxShadow: "none",
  },
});

const MarkAvgComponent = styled(SliderValueLabel)<any>`
  fontSize: 12,
  color: "white",
  fontWeight: "normal",
  background: "none",
  "&.MuiSlider-valueLabelOpen": {
    transform: ${(props) =>
      props.selectedDirection === Direction.LTR
        ? "scaleX(1)"
        : "translateY(40%) scale(1) scaleX(-1)"},
},`;

const ThumbMinMaxComponent = styled(SliderThumb)({
  height: 0,
  width: 0,
});

const EsgSlider = styled(Slider)<any>`
height: ".4rem",
position: "relative",
transform: ${(props) =>
  props.selectedDirection === Direction.LTR ? "scaleX(1)" : "scaleX(-1)"},
"& .MuiSlider-mark": {
  height: 0,
  width: 0,
},
"& .MuiSlider-markLabel": {
  transform: ${(props) =>
    props.selectedDirection === Direction.LTR
      ? "scaleX(1)"
      : "scaleX(-1) translateX(50%)"}
  fontSize: 14,
},
"& .MuiSlider-track": {
  backgroundColor: "#8ED0DF",
  border: "none",
},
`;

const ESGCard = (props: Props) => {
  const { printing } = usePrintStore();
  const { letterGrade, grade, average, min, max } = props;
  const marks: Mark[] = [];

  if ((min && min > 10) || !min) {
    marks.push({ value: 0, label: "0" });
  }
  if ((max && max < 90) || !max) {
    marks.push({ value: 100, label: "100" });
  }
  if (min && min >= 0 && min < max) {
    marks.push({
      value: min,
      label: min.toString(),
    });
  }
  if (max && max >= 0 && max <= 100) {
    marks.push({
      value: max,
      label: max.toString(),
    });
  }
  const { selectedLanguage, selectedDictionary, selectedDirection } =
    useSelectedLanguage();

  const MarkAvgComponentHOC = () => {
    return <MarkAvgComponent selectedDirection={selectedDirection} />;
  };

  const MarkGradeComponentHOC = () => {
    return <MarkGradeComponent selectedDirection={selectedDirection} />;
  };

  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {(selectedDictionary as any).esgGradeCell1}
        </Typography>
        <Grid
          container
          p={{ xs: "1rem .8rem 1rem", lg: "2rem 2rem 1rem" }}
          justifyContent="center"
        >
          <Grid item sm={3} xs={printing ? 3 : 12} mx="auto">
            <Progress.ProgressGraph
              progress={grade || 0}
              itemSize={printing ? 90 : 120}
            />
          </Grid>
          <Grid container item sm={9} xs={printing ? 9 : 12}>
            <Grid container alignItems="center" justifyContent="left">
              <Grid item sm={3} xs={printing ? 3 : 12}>
                <Typography variant="h6" pl="1rem" gutterBottom>
                  {(selectedDictionary as any).esgGradeCell2}
                </Typography>
              </Grid>
              <Grid item sm={9} xs={printing ? 9 : 12}>
                <EsgSlider
                  disabled
                  selectedDirection={selectedDirection}
                  step={null}
                  track={false}
                  value={grade || 0}
                  valueLabelDisplay="on"
                  components={{
                    Thumb: ThumbGradeComponent,
                    ValueLabel: MarkGradeComponent,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={printing ? 3 : 12}>
                <Typography variant="h6" pl="1rem" gutterBottom>
                  {(selectedDictionary as any).esgGradeCell3}
                </Typography>
              </Grid>
              <Grid item sm={9} xs={printing ? 9 : 12}>
                <EsgSlider
                  disabled
                  selectedDirection={selectedDirection}
                  step={null}
                  track={false}
                  value={average || 0}
                  valueLabelDisplay="on"
                  components={{
                    Thumb: ThumbAvgComponent,
                    ValueLabel: MarkAvgComponent,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={printing ? 3 : 12}>
                <Typography variant="h6" pl="1rem" pb="1.3rem">
                  {(selectedDictionary as any).esgGradeCell4}
                </Typography>
              </Grid>
              <Grid item sm={9} xs={printing ? 9 : 12}>
                <EsgSlider
                  disabled
                  selectedDirection={selectedDirection}
                  step={null}
                  value={[min || 0, max || 100]}
                  marks={marks}
                  components={{ Thumb: ThumbMinMaxComponent }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default ESGCard;
