import { useLazyQuery } from "@apollo/client";
import { formatCompanies } from "formatters";
import { COMPANIES_CSV, USER_COMPANIES } from "graphql/query";
import React, { createContext, useEffect, useState } from "react";
import { useCompaniesFilters } from "stores";
import { Company } from "types";

interface CompaniesContextState {
  companies: Company[];
  companiesCount: number;
  csvCompanies: Company[];
  error: any;
  getCompanies: () => Promise<any>;
  getCsvCompanies: () => Promise<any>;
  loadingCompanies: boolean;
  loadingCsvCompanies: boolean;
  loadingMoreCompanies: boolean;
  page: number;
  setPage: (page: number) => void;
}

export const CompaniesContext = createContext({} as CompaniesContextState);

export const CompaniesProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { queryFilters } = useCompaniesFilters();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [page, setPage] = useState<number>(0);

  const [getCompanies, { data, loading: loadingCompanies, error }] =
    useLazyQuery(USER_COMPANIES, {
      variables: {
        pagination: { page, limit: 18 },
        filters: queryFilters,
      },
    });

  const [
    getCsvCompanies,
    { data: csvCompanies, loading: loadingCsvCompanies, error: csvError },
  ] = useLazyQuery(COMPANIES_CSV, {
    variables: {
      pagination: { page: 0, limit: -1 },
      filters: queryFilters,
    },
  });

  useEffect(() => {
    if (data?.myCompanies?.data) {
      const newCompanies = formatCompanies(data?.myCompanies?.data);
      if (page > 0) {
        setCompanies(companies.concat(newCompanies));
        setCompaniesCount(data?.myCompanies?.count);
      } else {
        setCompanies(newCompanies);
        setCompaniesCount(data?.myCompanies?.count);
      }
    }
  }, [data?.myCompanies?.data]);

  const iState = {
    companies: companies,
    companiesCount,
    csvCompanies: formatCompanies(csvCompanies?.myCompanies?.data) || [],
    error: error && csvError,
    getCompanies,
    getCsvCompanies,
    loadingCompanies: loadingCompanies && !(page > 0),
    loadingCsvCompanies,
    loadingMoreCompanies: loadingCompanies && page > 0,
    page,
    setPage,
  } as CompaniesContextState;

  return (
    <CompaniesContext.Provider value={iState}>
      {children}
    </CompaniesContext.Provider>
  );
};
