import { InputBase } from "@mui/material";
import { Direction } from "language";
import styled from "styled-components";

export const HeaderListLine = styled.div`
  display: flex;
  cursor: default;
  width: 100%;
  text-align: center;
  align-items: center;
  color: #1d8ea8;
  font-size: 1.5rem;
  background: white;
`;

export const ListLine = styled.div<any>`
  display: flex;
  width: 100%;
  padding: 0;
  text-align: ${(props) =>
    props.selectedDirection === Direction.LTR ? "left" : "right"};
  align-items: center;
  font-size: 0.9rem;
`;

export const DrawerBox = styled.div<any>`
  bottom: 0;
`;

//direction: ${(props) =>
//props.selectedDirection === Direction.LTR ? "ltr" : "rtl"};
//

export const Search = styled.div`
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  border: 2px solid #196275;
  width: 75%;
  margin: auto;
`;

export const StyledInputBase = styled(InputBase)`
  && {
    width: 100%;
    padding-left: calc(1em + 0.5rem);
    padding-right: calc(1em + 0.5rem);
    & .muiinputbase-input: {
      padding: 0.8rem;
    }
  }
`;

export const SearchIconWrapper = styled.div`
  padding: 0.25rem 0.7rem 0;
  pointer-events: none;
  transform: scaleX(-1);
  color: #000f26;
`;

export const Box = styled.div`
  padding: 2.5rem 2rem 2.5rem 2rem;
  overflow-y: scroll;
  position: relative;
  display: flex;
  gap: 2rem;
  align-content: flex-start;
  justify-content: space-between;
  flex: 1 1 33.3%;
  width: 100%;
  direction: rtl;
`;
