import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelectedLanguage } from "language";
import * as SC from "./style";

const OutlinedCard = (props: any) => {
  const { disclaimer } = props;
  const { selectedDictionary } = useSelectedLanguage();

  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" gutterBottom>
        {(selectedDictionary as any).disclaimerCell1}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              component="div"
              p="0 .3rem"
              style={{ whiteSpace: "pre-line" }}
            >
              {disclaimer}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default OutlinedCard;
