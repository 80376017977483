import styled from "styled-components";

export const CompaniesScreenWrapper = styled.div``;

export const PageWrapper = styled.div<any>`
 
  position: relative;
  align-items: stretch;
`;

// margin: ${(props) =>
//   props.selectedDirection === Direction.LTR ? "0 0 0 16rem" : "0 16rem 0 0"};

export const Box = styled.div<any>`
  padding: 2.5rem 2rem 2.5rem 2rem;
  overflow-y: scroll;
  position: relative;
  display: flex;
  gap: 2rem;
  align-content: flex-start;
  justify-content: space-between;
  flex: 1 1 33.3%;
  width: 100%;

`;

// direction: ${(props) =>
//   props.selectedDirection === Direction.LTR ? "ltr" : "rtl"};

export const Column = styled.div`
  height: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
