import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Icon from "components/icon";
import { useSelectedLanguage } from "language";
import { InvolvementLevel } from "types";
import * as SC from "./style";

interface Props {
  risk?: number;
  management?: number;
  label?: string;
}

const OutlinedCard = (props: Props) => {
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();
  const { label, management, risk } = props;
  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" style={{ textAlign: "center" }} gutterBottom>
          {label}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              sx={{ textAlign: "center" }}
              variant="h5"
              component="div"
              color="secondary.main"
            >
              {(selectedDictionary as any).risk}
            </Typography>
            <Typography
              sx={{ textAlign: "center" }}
              variant="h3"
              component="div"
              color="secondary.main"
            >
              {risk ? (
                <Icon
                  name={"RED_" + Object.keys(InvolvementLevel)[risk - 1]}
                  size="5rem"
                  customHeight="1.5rem"
                />
              ) : (
                "-"
              )}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Divider variant="middle" orientation="vertical" />
          </Grid>
          <Grid item xs={5}>
            <Typography
              sx={{ textAlign: "center" }}
              variant="h5"
              component="div"
              color="primary.main"
            >
              {(selectedDictionary as any).management}
            </Typography>
            <Typography
              sx={{ textAlign: "center" }}
              variant="h3"
              component="div"
              color="primary.main"
            >
              {(management && (management - 1) * 25 + "%") || "-"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default OutlinedCard;
