import React, { createContext, useState } from "react";

interface LandingPageContext {
  mode: string;
  setMode: (mode: string) => void;
}

export enum Mode {
  FUNDS = "FUNDS",
  COMPANIES = "COMPANIES",
}

export const LandingPageContext = createContext({} as LandingPageContext);

export const LandingPageProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [mode, setMode] = useState(Mode.COMPANIES);
  const iState = {
    mode,
    setMode,
  } as LandingPageContext;

  return (
    <LandingPageContext.Provider value={iState}>
      {children}
    </LandingPageContext.Provider>
  );
};
