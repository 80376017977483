import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Icon from "components/icon";
import { useSelectedLanguage } from "language";
import { usePrintStore } from "stores";
import { Activity, BadActivityTypes } from "types";
import * as SC from "./style";

interface Props {
  name: string;
  activities: Activity[];
}

const Item = (props: Activity) => {
  const { type, description, involvement } = props;
  const activityType = type.toString();
  const { printing } = usePrintStore();
  const { selectedTypeTitles } = useSelectedLanguage();

  let color = "#AAF4E7";

  if (type in BadActivityTypes) {
    color = "#FF7B5F";
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="start"
      item
      xs={printing ? 6 : 12}
      md={6}
    >
      <Grid item xs={3}>
        <Box width="5rem" p=".5rem">
          <Icon
            name={activityType}
            default="default_negative"
            color={color}
            size="1.5rem"
            text={selectedTypeTitles[type]}
          />
        </Box>
      </Grid>
      <Grid item xs={3} pt="1.9rem">
        <Typography variant="body2" p="0 .3rem">
          {selectedTypeTitles[involvement] || "-"}
        </Typography>
      </Grid>
      <Grid item xs={6} pt="1.8rem">
        <Typography variant="subtitle1">{description || "-"}</Typography>
      </Grid>
    </Grid>
  );
};

const ActivitiesCard = (props: Props) => {
  const { name, activities } = props;
  const filteredActivities = activities?.filter((i: any) => i.type);
  const { selectedDictionary } = useSelectedLanguage();

  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {name}
        </Typography>
        <Grid container direction="row" spacing={2}>
          {filteredActivities?.map((c, i) => (
            <Item {...c} key={i} />
          ))}
          {(!filteredActivities || filteredActivities?.length === 0) && (
            <Typography
              variant="subtitle1"
              pt="1rem"
              style={{ margin: "1rem" }}
            >
              {(selectedDictionary as any).activityCell1}
            </Typography>
          )}
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default ActivitiesCard;
