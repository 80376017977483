import Grid from "@mui/material/Grid";
import loading from "assets/loading.gif";
import React from "react";
import { LoadingProps } from "types";

const Loading = React.forwardRef<HTMLDivElement, LoadingProps>((props, ref) => (
  <Grid
    {...props}
    ref={ref}
    container
    justifyContent="center"
    alignItems="center"
    sx={{
      width: props.width || "100%",
      height: props.height || "100%",
      backgroundColor: props.filter ? "rgba(203, 221, 248, 0.5)" : null,
      zIndex: "1500",
      backdropFilter: props.filter ? "blur(4px)" : null,
      position: "absolute",
      top: 0,
      right: 0,
    }}
  >
    <img src={loading} alt="loading..." height="100" />
  </Grid>
));

export default Loading;
