import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import * as SC from "./style";
interface Props {
  name: string;
  content: string;
}

const Blockquote: React.ElementType = styled("blockquote")({
  margin: 0,
  paddingRight: "1em",
  borderRight: "0.5em #eee solid",
});

const convertHtmlToBrackets = (fieldText: any) => {
  const htmlEntities = /&(lt|gt);/;
  const isHtmlEntityUsed = htmlEntities.test(fieldText);
  if (isHtmlEntityUsed) {
    const lines = fieldText.split("\n");
    const updatedLines = lines.map((line: any) => {
      if (line.includes("lt") || line.includes("gt")) {
        return line + "\n";
      }
      return line;
    });
    return updatedLines.join("\n").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  }
  return fieldText;
};

const BasicCard = (props: Props) => {
  const { name, content } = props;
  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {name}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2" component="div" p="0 .3rem">
              {(
                <ReactMarkdown
                  linkTarget="_blank"
                  remarkPlugins={[remarkBreaks]}
                  rehypePlugins={[rehypeRaw, remarkGfm]}
                  children={convertHtmlToBrackets(
                    DOMPurify.sanitize(content?.replace(/\n/g, "&nbsp;\n"))
                  )}
                  components={{ blockquote: Blockquote }}
                />
              ) || "-"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default BasicCard;
