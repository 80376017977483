import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        confirmed
        username
        letterGrading
        hasFundsFeature
        hasClimateGrade
        hasCompanyGraph
        orgId
      }
    }
  }
`;

export const GRADING_UPDATE = gql`
  mutation updateUserConfig($input: Input!) {
    updateUserConfig(input: $input) {
      user {
        id
        confirmed
        username
        letterGrading
        hasFundsFeature
      }
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation login($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;
