import { Box, CircularProgress, circularProgressClasses } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const CircularLetter = (props: any) => {
  const { letterGrade } = props;
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={props.itemSize}
        thickness={3}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "primary.main" : "primary.main",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={props.itemSize}
        thickness={3}
        value={Math.max(props.progress, 0)}
      />
      <Grid
        sx={{
          top: props.itemSize / 4,
          position: "absolute",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          fontSize={props.itemSize > 80 ? "2rem" : "1.25rem"}
          color="primary.main"
          textAlign="center"
          sx={{ direction: "ltr" }}
        >
          {letterGrade}
        </Typography>
      </Grid>
    </Box>
  );
};

export default CircularLetter;
