import { useLazyQuery } from "@apollo/client";
import { formatFunds } from "formatters";
import { FUNDS } from "graphql/query";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useFundsFilters } from "stores";
import { Fund } from "types";
import { AuthContext } from "./auth";

interface FundsContextState {
  funds: Fund[];
  fundsCount: number;
  error: any;
  getFunds: () => Promise<any>;
  loadingFunds: boolean;
  loadingMoreFunds: boolean;
  page: number;
  setPage: (page: number) => void;
}

export const FundsContext = createContext({} as FundsContextState);

export const FundsProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [funds, setFunds] = useState<Fund[]>([]);
  const [fundsCount, setFundsCount] = useState(0);
  const [page, setPage] = useState<number>(0);
  const { userData } = useContext(AuthContext);
  const { searchTermFunds, queryFilters } = useFundsFilters();

  const [getFunds, { data, loading: loadingFunds, error }] = useLazyQuery(
    FUNDS,
    {
      variables: {
        pagination: { page, limit: 18 },
        filters: queryFilters,
      },
    }
  );

  useEffect(() => {
    if (!loadingFunds && data) {
      const formattedData = formatFunds(data?.fundsFilteredQuery?.data || []);
      setFunds(formattedData);
      setFundsCount(data?.fundsFilteredQuery?.count);
    }
  }, [loadingFunds, data]);

  useEffect(() => {
    if (data?.funds?.data) {
      const newFunds = data?.fundsFilteredQuery?.data;
      if (page > 0) {
        setFunds(funds.concat(newFunds));
        setFundsCount(data?.fundsFilteredQuery?.count);
      } else {
        setFunds(funds.concat(newFunds));
        setFundsCount(data?.fundsFilteredQuery?.count);
      }
    }
  }, [data?.funds?.data]);

  const iState = {
    funds: funds,
    fundsCount,
    error: error,
    getFunds,
    loadingFunds: loadingFunds && !(page > 0),
    loadingMoreFunds: loadingFunds && page > 0,
    page,
    setPage,
  } as FundsContextState;

  return (
    <FundsContext.Provider value={iState}>{children}</FundsContext.Provider>
  );
};
