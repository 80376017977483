import styled from "styled-components";

export const Background = styled.div`
  background: linear-gradient(58.09deg, #000F26 63.02%, #033F9A 120.32%);
  height: 100vh;
  padding: 2.5em;
`

export const HeaderLogoWrapper = styled.div`
  color: white;
  text-align: left;
`

export const BodyWrapper = styled.div`
  color: white;
  height: calc(100vh-6rem);
`
