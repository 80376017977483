import styled from "styled-components";

export const PageWrapper = styled.div`
  margin-right: 16rem;
  position: relative;
  align-items: stretch;
`;

export const Box = styled.div<any>`
  padding: 2.5rem 2rem 2.5rem 2rem;
  overflow-y: scroll;
  position: relative;
  display: flex;
  gap: 2rem;
  align-content: flex-start;
  justify-content: space-between;
  flex: 1 1 33.3%;
  width: 100%;
`;
