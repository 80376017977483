import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { FundDialog } from "components/Dialog";
import { useSelectedLanguage } from "language";
import { useState } from "react";
import { Fund } from "types";
import * as SC from "./style";
const FundCard = (props: Fund) => {
  const [expanded, setExpanded] = useState(false);
  const { selectedDictionary } = useSelectedLanguage();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <SC.Card variant="outlined">
        <SC.CompanyCard>
          <CardActions>
            <Box
              onClick={handleExpandClick}
              sx={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: ".5rem 1rem",
              }}
            >
              <Typography variant="body1" color="primary.main">
                {props.fundName}
              </Typography>
              {expanded ? (
                <ArrowDropUpIcon fontSize="small" />
              ) : (
                <ArrowDropDownIcon fontSize="small" />
              )}
            </Box>
          </CardActions>
        </SC.CompanyCard>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ padding: "0 1rem" }}>
            <Box>
              <Typography variant="subtitle1" fontWeight="fontWeightMedium">
                {(selectedDictionary as any).funds.fundNumber + " :"}
              </Typography>
              <Typography pr=".5rem" variant="subtitle1">
                {props?.fundId || "-"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="fontWeightMedium">
                {(selectedDictionary as any).funds.fundGrade + " :"}
              </Typography>
              <Typography pr=".5rem" variant="subtitle1">
                {props?.fundWeightedGrade || "-"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="fontWeightMedium">
                {(selectedDictionary as any).funds.percentAssessed + " :"}
              </Typography>
              <Typography pr=".5rem" variant="subtitle1">
                {props?.percentAssessedByGreeneye || "-"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="fontWeightMedium">
                {(selectedDictionary as any).funds.fundDate + " :"}
              </Typography>
              <Typography pr=".5rem" variant="subtitle1">
                {props?.fundDate || "-"}
              </Typography>
            </Box>
            <Box textAlign="center" pt="1.2rem">
              <Button
                onClick={handleClickOpen}
                variant="contained"
                disableElevation
                size="small"
              >
                {(selectedDictionary as any).funds.fundDetails}
              </Button>
            </Box>
          </CardContent>
        </Collapse>
      </SC.Card>
      <FundDialog
        props={props}
        open={open}
        handleClose={handleClose}
      ></FundDialog>
    </>
  );
};

export default FundCard;
