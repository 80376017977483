import styled from "styled-components";


export const Box = styled.div`
    padding: 2.5rem 2rem 2.5rem 2rem;
    overflow-y: scroll;
    position: relative;
    display: flex;
    gap: 2rem;
    align-content: flex-start;
    justify-content: space-between;
    flex: 1 1 33.3%;
    width: 100%;
`;

export const Column = styled.div`
    height: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
`;