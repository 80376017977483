import { useState } from "react";
import languages from "./languages/language";

export enum Direction {
  RTL = "rtl",
  LTR = "ltr",
}

export const useSelectedLanguage = () => {
  const envLang: string | undefined = process.env.REACT_APP_LANGUAGE;
  const language =
    envLang !== undefined ? (languages as any)[envLang] : languages.he;
  const [selectedLanguage, setSelectedLanguage] = useState(language.lString);
  const [selectedDictionary, setSelectedDictionary] = useState(
    language.language
  );
  const [selectedTypeTitles, setSelectedTypeTitles] = useState(
    language.typeTitles
  );
  const [selectedCsvHeaders, setSelectedCsvHeaders] = useState(
    language.csvHeaders
  );
  const [selectedDirection, setSelectedDirection] = useState(
    language.direction
  );

  return {
    selectedLanguage,
    selectedDictionary,
    selectedTypeTitles,
    selectedCsvHeaders,
    selectedDirection,
  };
};
