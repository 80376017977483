import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CompanyContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext } from "react";
import { CountryEntity } from "types";
import * as SC from "./style";

const OutlinedCard = () => {
  const { company } = useContext(CompanyContext);
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" textAlign="center" gutterBottom>
          {(selectedDictionary as any).activeCountriesCell1}
        </Typography>
        {company?.countries ? (
          <Grid container>
            <Grid item xs={6}>
              <Typography textAlign="center" variant="h5" component="div">
                {(selectedDictionary as any).activeCountriesCell2}
              </Typography>
              <Typography
                textAlign="center"
                variant="body2"
                component="div"
                p="0 .3rem"
              >
                {company?.countries
                  ?.filter((i: CountryEntity) => i.isDeveloped)
                  .map((i: CountryEntity) => i.name)
                  .join(", ")}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Divider variant="middle" orientation="vertical" />
            </Grid>
            <Grid item xs={5}>
              <Typography textAlign="center" variant="h5" component="div">
                {(selectedDictionary as any).activeCountriesCell3}
              </Typography>
              <Typography
                textAlign="center"
                variant="body2"
                component="div"
                p="0 .3rem"
              >
                {company?.countries
                  ?.filter((i: CountryEntity) => !i.isDeveloped)
                  .map((i: CountryEntity) => i.name)
                  .join(", ")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography
            variant="body2"
            textAlign="center"
            p="0 .3rem"
          ></Typography>
        )}
      </CardContent>
    </SC.Card>
  );
};

export default OutlinedCard;
