import { Fade } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Appbar, Progress } from "components";
import { AuthContext, CompanyContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { usePrintStore } from "stores";
import { AuthStatus } from "types";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { authStatus } = useContext(AuthContext);
  let location = useLocation();

  if (authStatus !== AuthStatus.SignedIn) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
};

export const RequireNotAuth = ({ children }: { children: JSX.Element }) => {
  const { authStatus } = useContext(AuthContext);
  const location = useLocation();
  if (authStatus === AuthStatus.SignedIn) {
    //@ts-ignore
    return (
      <Navigate
        to={(location?.state as any)?.from?.pathname || "/"}
        state={{ from: location }}
        replace
      />
    );
  }
  return children;
};

function App() {
  const location = useLocation();
  const { loadingCompany } = useContext(CompanyContext);
  const { printing } = usePrintStore();
  const loading = loadingCompany || printing;
  const loadingProps = {
    filter: "true",
    height: "100%",
  };
  const { selectedDirection } = useSelectedLanguage();
  return (
    <Grid
      height="100%"
      alignItems="stretch"
      //  dir={selectedDirection === Direction.LTR ? "ltr" : "rtl"}
    >
      <Fade in={loading}>
        <Progress.Loading {...loadingProps} />
      </Fade>
      {location?.pathname !== "/signin" && <Appbar />}
      {/* <Drawer /> */}
      <Outlet />
    </Grid>
  );
}

export default App;
