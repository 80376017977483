import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Chart from "chart.js/auto";
import { AssetTable } from "components/Accordion";
import { ProgressGraph } from "components/Progress";
import * as _ from "lodash";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Asset, Fund, fundHistory } from "types";

import { Collapse, IconButton } from "@mui/material";
import { Direction, useSelectedLanguage } from "language";
import * as SC from "./style";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogProps {
  props: Fund;
  open: boolean;
  handleClose: any;
}

const FundDialog = ({ props, open, handleClose }: DialogProps) => {
  const [toggle, setToggle] = useState(false);
  const { selectedDictionary, selectedDirection } = useSelectedLanguage();
  const getGradeFromPrevDate = (
    index: number,
    dates: string[],
    funds: fundHistory[]
  ) => {
    for (let x = index; x >= 0; --x) {
      const point = funds?.find((f) => f.fundDate === dates[x]);
      if (point) {
        return point.weightedGrade;
      }
    }
    return null;
  };
  const prepChartData = () => {
    const fundHistory = _.cloneDeep(props.fundHistory);
    const benchmarks = _.cloneDeep(props.benchmark.fundHistory);
    const allDates = new Set([
      ...fundHistory.map((f) => f.fundDate),
      ...(benchmarks?.map((f) => f.fundDate) || []),
    ]);
    const sortedArrayDate = Array.from(allDates).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    const dataset = [];
    dataset.push({
      label: `${(selectedDictionary as any).funds.fundGrade}`,
      data: sortedArrayDate.map((date, index) => {
        const point = fundHistory.find((f) => f.fundDate === date);
        return point
          ? point.weightedGrade
          : getGradeFromPrevDate(index, sortedArrayDate, fundHistory);
      }),
      pointRadius: 0,
      backgroundColor: "rgba(29,142,168,0.2)",
      borderWidth: 1,
      borderColor: "rgba(29,142,168,1)",
      stepped: true,
    });
    dataset.push({
      label: `${(selectedDictionary as any).funds.benchmarkGrade}`,
      data: sortedArrayDate.map((date, index) => {
        const point = benchmarks?.find((f) => f.fundDate === date);
        return point
          ? point.weightedGrade
          : getGradeFromPrevDate(index, sortedArrayDate, benchmarks);
      }),
      pointRadius: 0,
      backgroundColor: "rgba(46,49,58,0.2)",
      borderWidth: 1,
      borderColor: "rgba(46,49,58,1)",
      stepped: true,
    });
    return { sortedArrayDate, dataset };
  };
  const LineChart = () => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const dataset = prepChartData();
    useEffect(() => {
      const ctx = chartRef?.current?.getContext("2d");
      if (!ctx) return;

      const chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: dataset.sortedArrayDate,
          datasets: dataset.dataset,
        },
        options: {
          responsive: true,
          interaction: {
            intersect: false,
            axis: "x",
          },
          plugins: {},
          scales: {
            x: {
              title: {
                display: true,
                text: `${(selectedDictionary as any).funds.date}`,
              },
            },

            y: {
              title: {
                display: true,
                text: `${(selectedDictionary as any).funds.weightedGrade}`,
              },
              beginAtZero: true,
              suggestedMax: 100,
            },
          },
        },
      });
      return () => chart.destroy();
    }, []);
    return <canvas ref={chartRef}></canvas>;
  };

  const percentOfRedFlagSecurities = (assets: Asset[]) => {
    return assets
      .filter((asset) => asset.isRedFlag)
      .reduce((sum, curr) => {
        sum += curr.normalizedWeight;
        return sum;
      }, 0)
      .toFixed(2);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{
        direction: `${selectedDirection === Direction.LTR ? "ltr" : "rtl"}`,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.fundName}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            {(selectedDictionary as any).funds.back}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgb(224 232 233)",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <Grid
              container
              spacing={1}
              alignItems="stretch"
              className="padding"
            >
              <Grid item xs={12}>
                <SC.Card variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                      alignItems="stretch"
                      justifyContent="center"
                      className="padding"
                    >
                      <Grid item xs={12}>
                        <Typography>
                          {(selectedDictionary as any).funds.fundGrade}
                        </Typography>
                        <ProgressGraph
                          progress={props?.fundWeightedGrade || -1}
                          itemSize={80}
                        />
                      </Grid>

                      {/* <Grid item xs={4}>
                        <Typography>
                          {(selectedDictionary as any).funds.goodProducts}
                        </Typography>
                        <Typography sx={{ color: "#1D8EA8" }}>
                          {`*${props?.sdgPercent || 0} %`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {
                            (selectedDictionary as any).funds
                              .controversialActivities
                          }
                        </Typography>
                        <Typography sx={{ color: "#1D8EA8" }}>
                          {`*${props?.badActivitiesPercent || 0} %`}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={4}>
                        <Typography>
                          {(selectedDictionary as any).funds.redFlags}
                        </Typography>
                        <Typography sx={{ color: "#1D8EA8" }}>
                          {`% ${
                            percentOfRedFlagSecurities(props.assets) || 0
                          }*`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ fontSize: "small", marginTop: "2rem" }}>
                      {`*${(selectedDictionary as any).funds.fundsDisclaimer}`}
                    </Grid>
                  </CardContent>
                </SC.Card>
              </Grid>
              <Grid item xs={12}>
                <SC.Card variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                      alignItems="stretch"
                      justifyContent="center"
                      className="padding"
                    >
                      <Grid item xs={12}>
                        <Typography>{`${
                          (selectedDictionary as any).funds.benchmark
                        }
 : ${props?.benchmark?.fundName || "-"}`}</Typography>
                        <ProgressGraph
                          progress={props?.benchmark?.fundWeightedGrade || -1}
                          itemSize={80}
                          color={"#2E313A"}
                        />
                      </Grid>
                      {/* <Grid item xs={4}>
                        <Typography>
                          {(selectedDictionary as any).funds.goodProducts}
                        </Typography>
                        <Typography sx={{ color: "#2E313A" }}>
                          {`*${props?.benchmark?.sdgPercent || 0} %`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {
                            (selectedDictionary as any).funds
                              .controversialActivities
                          }
                        </Typography>
                        <Typography sx={{ color: "#2E313A" }}>
                          {`*${props?.benchmark?.badActivitiesPercent || 0} %`}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={4}>
                        <Typography>
                          {(selectedDictionary as any).funds.redFlags}
                        </Typography>
                        <Typography sx={{ color: "#2E313A" }}>
                          {`% ${
                            percentOfRedFlagSecurities(
                              props?.benchmark?.assets
                            ) || 0
                          }*`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ fontSize: "small", marginTop: "2rem" }}>
                      {`*${(selectedDictionary as any).funds.fundsDisclaimer}`}
                    </Grid>
                  </CardContent>
                </SC.Card>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              background: "white",
              borderRadius: "5px",
              marginTop: "0.5rem",
              marginBottom: toggle ? "0.5rem" : "",
            }}
          >
            <IconButton
              aria-label="expand/collapse graph"
              size="small"
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              {toggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Collapse in={toggle} timeout="auto" unmountOnExit>
              <SC.Card variant="outlined" sx={{ marginTop: "0.5rem" }}>
                <CardContent>
                  <div>{props && <LineChart></LineChart>}</div>
                </CardContent>
              </SC.Card>
            </Collapse>
          </div>
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          <AssetTable assets={props.assets} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FundDialog;
