import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Card } from "components";
import { useSelectedLanguage } from "language";
import { useEffect } from "react";
import { Incident } from "types";
import * as SC from "./style";

interface Props {
  incidents: Incident[];
}

const IncidentsReport = (props: Props) => {
  const { incidents } = props;
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  useEffect(() => {
    incidents?.sort((a, b) => (new Date(a?.date) > new Date(b?.date) ? 1 : -1));
  }, []);

  return (
    <SC.Card variant="outlined">
      <CardContent
        sx={{
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Grid container sx={{ background: "#FBFBFB" }} p="1rem">
          <Grid item xs={4}>
            <Typography variant="h4">
              {(selectedDictionary as any).incidentsReportCell1}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4">
              {(selectedDictionary as any).incidentsReportCell2}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4">
              {(selectedDictionary as any).incidentsReportCell3}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {incidents?.map((incident: Incident, i: number) => {
            return (
              <Box key={i}>
                <Card.CompanyIncident {...incident} />
                {i !== incidents?.length - 1 && <Divider />}
              </Box>
            );
          })}
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default IncidentsReport;
