import { CompanyContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext } from "react";
import BasicCard from "./basic";

const OutlinedCard = () => {
  const { company } = useContext(CompanyContext);
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  const name = selectedDictionary.sources;
  const content = company?.source || "";

  return <BasicCard name={name} content={content} />;
};

export default OutlinedCard;
