import * as Funds from "./Accordion";
import Appbar from "./Appbar";
import * as Card from "./Card";
import * as Dialog from "./Dialog";
import Drawer from "./Drawer";
import * as Progress from "./Progress";

const getEventFlagText = (company: any) => {
  const { eventsFlag } = company;
  if (eventsFlag === "None" || !eventsFlag) {
    return "אין";
  } else if (eventsFlag === "Critical") {
    return "אירועים קריטיים";
  } else {
    return "התרעה";
  }
};

export { Appbar, Card, Dialog, Drawer, Funds, Progress, getEventFlagText };

