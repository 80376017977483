import { heIL } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { Direction } from "language";

const rtlStyle = {
  styleOverrides: {
    root: {
      '&[dir="rtl"]': {
        transform: "scaleX(-1)",
      },
    },
  },
};

const ltrStyle = {
  styleOverrides: {
    root: {
      '&[dir="ltr"]': {},
    },
  },
};

export const theme = (selectedDirection: any) => {
  return createTheme(
    {
      palette: {
        primary: {
          main: "#1D8EA8",
          light: "#1BA6C6",
        },
        secondary: {
          main: "#FD5B00",
        },
        text: {
          primary: "#202020",
          secondary: "#727272",
        },
        grey: {
          50: "#E5EFFF",
          100: "#FBFBFB",
          200: "#E5E5E5",
          900: "#2E313A",
        },
      },
      typography: {
        fontFamily: ["Rubik", "sans-serif"].join(),
        h1: {
          fontWeight: 900,
          paddingBottom: "1rem",
          fontSize: "2.125rem",
        },
        h2: {
          fontWeight: "500",
          fontSize: "1.5rem",
        },
        h3: {
          fontWeight: "400",
          fontSize: "1.25rem",
        },
        h4: {
          fontWeight: "500",
          fontSize: "1rem",
          lineHeight: "1.5rem",
        },
        h5: {
          fontWeight: "400",
          fontSize: ".9rem",
        },
        h6: {
          fontWeight: "400",
          fontSize: ".7rem",
        },
        subtitle1: {
          fontWeight: "300",
          fontSize: ".8rem",
          display: "inline",
        },
        subtitle2: {
          fontWeight: "400",
          fontSize: ".65rem",
          lineHeight: ".8rem",
        },
        body1: {
          fontWeight: "400",
          fontSize: ".85rem",
        },
        body2: {
          fontWeight: "300",
          fontSize: ".9rem",
        },
      },
      shape: {
        borderRadius: 7,
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              zIndex: 1400,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              maxWidth: "none",
            },
          },
        },
        MuiCardActions: {
          styleOverrides: {
            root: {
              padding: "0",
              display: "flex",
              justifyContent: "space-between",
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              "&:last-child": {
                paddingBottom: "16px",
              },
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              padding: ".3rem",
              height: ".5rem",
              transform:
                selectedDirection === Direction.LTR ? "none" : "scaleX(-1)",
              color: "#196275",
              "&.Mui-disabled": {
                "&.MuiCheckbox-colorPrimary": {
                  color: "#1D8EA8",
                  "& ~ span": {
                    color: "#202020",
                  },
                },
                "&.MuiCheckbox-colorSecondary": {
                  color: "#8ED0DF",
                },
              },
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              borderRadius: "10px",
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              alignItems: "start",
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              padding: ".3rem 1rem",
              "&:hover": {
                backgroundColor: "#E8F6F9",
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              width: "100%",
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            root: {},
            primary: {
              fontSize: ".9rem",
            },
            secondary: {
              fontSize: ".7rem",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              maxWidth: "none",
              "&.MuiPaper-outlined": {
                maxWidth: "none",
              },
              "&.MuiPaper-rounded": {
                maxWidth: "none",
              },
            },
          },
        },
        MuiSvgIcon: selectedDirection === Direction.LTR ? ltrStyle : rtlStyle,
      },
    },
    heIL
  );
};
