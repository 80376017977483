import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card } from "components";
import { FundsContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useEffect } from "react";
import * as SC from "./style";

const FundsScreen = () => {
  const matchesMd = useMediaQuery("(min-width:800px)");
  const matchesLg = useMediaQuery("(min-width:1200px)");
  let columns = 1;
  if (matchesLg) {
    columns = 3;
  } else if (matchesMd) {
    columns = 2;
  }
  const { selectedDictionary } = useSelectedLanguage();

  const {
    funds,
    getFunds,
    loadingFunds,
    page,
    setPage,
    fundsCount,
    loadingMoreFunds,
  } = useContext(FundsContext);
  useEffect(() => {
    if (!loadingFunds && !funds?.length) getFunds();
  }, [getFunds, funds, loadingFunds]);
  const every_nth = (arr: any[], nth: number) =>
    arr.filter((_, i: number) => i % nth === nth - nth);
  const fetchPage = async () => {
    const newPage = page + 1;
    setPage(newPage);
  };

  return (
    <>
      <SC.Box>
        <SC.Column>
          {funds
            ? every_nth(funds, columns).map((c, i) => {
                return <Card.FundCard {...c} key={i} />;
              })
            : null}
        </SC.Column>
        {matchesMd && (
          <SC.Column>
            {funds
              ? every_nth(funds.slice(1), columns).map((c, i) => {
                  return <Card.FundCard {...c} key={i} />;
                })
              : null}
          </SC.Column>
        )}
        {matchesLg && (
          <SC.Column>
            {funds
              ? every_nth(funds.slice(2), columns).map((c, i) => {
                  return <Card.FundCard {...c} key={i} />;
                })
              : null}
          </SC.Column>
        )}
      </SC.Box>
      {funds?.length < fundsCount && !loadingFunds && (
        <Box textAlign="center" p="2rem" pt="0">
          <LoadingButton
            onClick={fetchPage}
            variant="contained"
            size="large"
            disableElevation
            disableRipple
            loading={loadingMoreFunds}
            loadingIndicator=""
          >
            <Typography variant="h5" color="white">
              <Typography variant="h5" color="white">
                {loadingMoreFunds
                  ? (selectedDictionary as any).loading
                  : (selectedDictionary as any).additionalFunds}
              </Typography>
            </Typography>
          </LoadingButton>
        </Box>
      )}
      {fundsCount === 0 && !loadingFunds && (
        <Box textAlign="center" p="2rem" pt="0">
          <Typography variant="h4" sx={{ m: "0 auto" }} color="primary">
            {(selectedDictionary as any).noResultFunds}
          </Typography>
        </Box>
      )}
    </>
  );
};
export default FundsScreen;
