import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ProgressGraph } from "components/Progress";
import Icon from "components/icon";
import { AuthContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext } from "react";
import { usePrintStore } from "stores";
import { InvolvementLevel } from "types";
import * as SC from "./style";

interface Props {
  grade: number;
  title: string;
  relevance: InvolvementLevel;
  sectorDescription: string;
  companyDescription: string;
  gradeConfig: any;
  company: any;
}

const OutlinedCard = (props: Props) => {
  const {
    grade,
    title,
    relevance,
    sectorDescription,
    companyDescription,
    gradeConfig,
    company,
  } = props;
  const { printing } = usePrintStore();
  const matchesSm = useMediaQuery("(min-width:600px)");
  const { selectedLanguage, selectedDictionary, selectedDirection } =
    useSelectedLanguage();
  const { userData } = useContext(AuthContext);
  const styles = {
    color: "#1d8ea8",
    backgroundColor: "white",
    padding: "1.5rem",
    border: "0.3rem solid #1d8ea8",
  };

  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {(selectedDictionary as any).companyEvaluationCell1} {title}
        </Typography>
        <Grid container>
          <Grid item xs={4} sm={3} container direction="column">
            <Grid item>
              <Typography
                variant="h4"
                style={{ fontSize: "0.9rem" }}
                component="div"
              >
                {(selectedDictionary as any).companyEvaluationCell2}
              </Typography>
            </Grid>
            <Grid item style={{ padding: "1rem 2.3rem", width: "10rem" }}>
              {InvolvementLevel[relevance] && (
                <Icon
                  name={"BLUE_" + InvolvementLevel[relevance]}
                  size={"3rem"}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="body2" component="div">
              {sectorDescription}
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="middle" />
        <Grid container pt="1rem">
          <Grid item xs={4} sm={3} container direction="column">
            <Grid item>
              <Typography
                variant="h4"
                style={{ fontSize: "0.9rem" }}
                component="div"
              >
                {(selectedDictionary as any).companyEvaluationCell3} {title}
              </Typography>
            </Grid>
            <Grid item style={{ padding: "1rem 2rem 0 2rem" }}>
              {userData.letterGrading ? (
                ""
              ) : (
                <ProgressGraph
                  progress={grade || 0}
                  itemSize={printing || !matchesSm ? 60 : 80}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography
              // textAlign={selectedDirection === Direction.LTR ? "left" : "right"}
              variant="body2"
              component="div"
              p="0 .3rem"
              style={{ whiteSpace: "pre-line" }}
              gutterBottom
            >
              {companyDescription}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default OutlinedCard;
