import { Fade, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Drawer, Progress } from "components";
import {
  AuthContext,
  CompaniesContext,
  FiltersContext,
  FundsContext,
} from "context";
import { LandingPageContext, Mode } from "context/landingPage";
import { useSelectedLanguage } from "language";
import { useContext } from "react";
import { CompaniesScreen, FundsScreen } from "screens";
import { useCompaniesFilters, useFundsFilters } from "stores";
import { LoadingProps } from "types";
import * as SC from "./style";

const LandingPage = () => {
  const { mode, setMode } = useContext(LandingPageContext);
  const { selectedDictionary, selectedDirection } = useSelectedLanguage();
  const { setSearchTerm } = useCompaniesFilters();
  const { userData } = useContext(AuthContext);
  const { setSearchTermFunds } = useFundsFilters();

  const Toggle = () => {
    const handleChanges = async (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string
    ) => {
      if (!newAlignment) return;
      setMode(newAlignment);
      setSearchTerm("");
      setSearchTermFunds("");
    };
    if (userData?.hasFundsFeature === null || !userData.hasFundsFeature) {
      return <></>;
    }
    return (
      <div
        style={{ display: "flex", justifyContent: "center", margin: "1rem" }}
      >
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          onChange={handleChanges}
          aria-label="Platform"
          style={{ direction: "ltr" }}
        >
          <ToggleButton value={Mode.FUNDS}>
            {(selectedDictionary as any).funds.funds}
          </ToggleButton>
          <ToggleButton value={Mode.COMPANIES}>
            {(selectedDictionary as any).funds.companies}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };
  const Companies = () => {
    const loadingProps: LoadingProps = {
      filter: "true",
    };
    const { loadingFilters } = useContext(FiltersContext);

    const { loadingCompanies, loadingCsvCompanies } =
      useContext(CompaniesContext);
    return (
      <>
        <div style={{ position: "relative" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ background: "white", maxWidth: "16rem" }}>
              <Drawer />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Toggle />
              <CompaniesScreen />
            </div>
          </div>
          {(loadingCompanies || loadingFilters) && (
            <SC.Box
              selectedDirection={selectedDirection}
              style={{
                display: "grid",
                border: "0 0 0 2px solid black",
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                height: "105vh",
              }}
            >
              <Fade
                in={loadingCompanies || loadingCsvCompanies || loadingFilters}
              >
                <Progress.Loading {...loadingProps} />
              </Fade>
            </SC.Box>
          )}
        </div>
      </>
    );
  };
  const Funds = () => {
    const loadingProps: LoadingProps = {
      filter: "true",
    };
    const { loadingFunds } = useContext(FundsContext);

    return (
      <div style={{ position: "relative" }}>
        <Grid container justifyContent="space-between">
          <Grid item md={12} xs={12}>
            <Toggle />
          </Grid>
          <Grid item md={12} xs={12}>
            <FundsScreen />
          </Grid>
        </Grid>
        {loadingFunds && (
          <SC.Box
            selectedDirection={selectedDirection}
            style={{
              display: "grid",
              border: "0 0 0 2px solid black",
              position: "absolute",
              top: 0,
              right: 0,
              width: "100%",
              height: "105vh",
            }}
          >
            <Fade in={loadingFunds}>
              <Progress.Loading {...loadingProps} />
            </Fade>
          </SC.Box>
        )}
      </div>
    );
  };

  return <>{mode === Mode.COMPANIES ? Companies() : Funds()}</>;
};

export default LandingPage;
