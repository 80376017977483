import { Button, InputBase, MenuItem, Toolbar } from "@mui/material";
import { Direction } from "language";
import styled, { css } from "styled-components";

export const AppBarWrapper = styled.div`
  z-index: 9;
  width: 100%;
  background: #2e313a;
`;

interface SubHeaderToolbarProps {
  selectedDirection: Direction;
}

export const SubHeaderToolbar = styled(Toolbar)<SubHeaderToolbarProps>`
  && {
    position: sticky;
    top: 0;
    z-index: 10;
    background: linear-gradient(88.37deg, #e8f6f9 -84.82%, #1d8ea8 53.28%);
    flex-direction: column;
    padding: 0.5rem 3rem;
    ${(props) =>
      props.selectedDirection === Direction.RTL &&
      css`
        align-items: right;
      `}
  }
`;

export const SubHeaderLogoWrapper = styled.div`
  width: 100%;
  margin-right: -4.37rem;
  display: flex;
  justify-content: space-between;
`;

export const Search = styled.div`
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  border: 2px solid #196275;
`;

export const SearchIconWrapper = styled.div<any>`
  padding: 0.25rem 0.7rem 0;
  pointer-events: none;
  color: #000f26;
  transform: ${(props) =>
    props.selectedDirection === Direction.LTR && css`scaleX(-1)`};
`;
export const StyledInputBase = styled(InputBase)`
  && {
    width: 100%;
    padding-left: calc(1em + 0.5rem);
    padding-right: calc(1em + 0.5rem);
    & .muiinputbase-input: {
      padding: 0.8rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #2e313a;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 10px;
    justify-self: left;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && .Mui-selected {
    background-color: rgba(29, 142, 168, 0.25);
  }
`;
