import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Icon from "components/icon";
import { FiltersContext } from "context";
import { Direction, useSelectedLanguage } from "language";
import { Fragment, useContext, useEffect, useState } from "react";
import { usePrintStore } from "stores";
import {
  InvolvementLevel,
  InvolvementLevelMap,
  IssueEntity,
  TextValuePair,
} from "types";
import * as SC from "./style";

const ColorGrid = (props: IssueEntity) => {
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();
  return (
    <SC.GraphContainer>
      <SC.GraphColumn
        style={{
          paddingTop: "2rem",
        }}
      >
        <SC.GraphBox />
        <SC.GraphBox />
        <SC.GraphBox />
        <SC.GraphBox />
        <SC.GraphBox>
          <Typography variant="subtitle2" color="secondary.main">
            {(selectedDictionary as any).companyIssueCell1}
          </Typography>
        </SC.GraphBox>
      </SC.GraphColumn>
      <SC.Graph>
        <SC.Circle
          style={{
            bottom: `${
              props.management ? (props.management - 1) * 2 + 1.6 : 0
            }rem`,
            left: `${props.risk ? (props.risk - 1) * 2 - 0.4 : 0}rem`,
          }}
        />
        <SC.GraphColumn>
          <SC.GraphBox4 />
          <SC.GraphBox5 />
          <SC.GraphBox6 />
          <SC.GraphBox7 />
          <SC.GraphBox>
            <Typography variant="subtitle2" color="secondary.main">
              5
            </Typography>
          </SC.GraphBox>
        </SC.GraphColumn>
        <SC.GraphColumn>
          <SC.GraphBox3 />
          <SC.GraphBox4 />
          <SC.GraphBox5 />
          <SC.GraphBox6 />
          <SC.GraphBox>
            <Typography variant="subtitle2" color="secondary.main">
              4
            </Typography>
          </SC.GraphBox>
        </SC.GraphColumn>
        <SC.GraphColumn>
          <SC.GraphBox2 />
          <SC.GraphBox3 />
          <SC.GraphBox4 />
          <SC.GraphBox5 />
          <SC.GraphBox>
            <Typography variant="subtitle2" color="secondary.main">
              3
            </Typography>
          </SC.GraphBox>
        </SC.GraphColumn>
        <SC.GraphColumn>
          <SC.GraphBox1 />
          <SC.GraphBox2 />
          <SC.GraphBox3 />
          <SC.GraphBox4 />
          <SC.GraphBox>
            <Typography variant="subtitle2" color="secondary.main">
              2
            </Typography>
          </SC.GraphBox>
        </SC.GraphColumn>
      </SC.Graph>
      <div style={{ marginTop: "-.3rem" }}>
        <SC.GraphBox>
          <Typography variant="subtitle2" color="primary.main">
            {(selectedDictionary as any).companyIssueCell2}
          </Typography>
        </SC.GraphBox>
        <SC.GraphBox>
          <Typography variant="subtitle2" color="primary.main">
            100
          </Typography>
        </SC.GraphBox>
        <SC.GraphBox>
          <Typography variant="subtitle2" color="primary.main">
            75
          </Typography>
        </SC.GraphBox>
        <SC.GraphBox>
          <Typography variant="subtitle2" color="primary.main">
            50
          </Typography>
        </SC.GraphBox>
        <SC.GraphBox>
          <Typography variant="subtitle2" color="primary.main">
            25
          </Typography>
        </SC.GraphBox>
        <SC.GraphBox>
          <Typography variant="subtitle2" color="primary.main">
            0
          </Typography>
        </SC.GraphBox>
      </div>
      <Typography></Typography>
    </SC.GraphContainer>
  );
};

interface BorderLinearProgressProps {
  direction?: Direction;
}

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(
  ({ theme, direction }) => ({
    height: 10,
    transform: direction === Direction.LTR ? "scaleX(1)" : "scaleX(-1)",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:
        theme.palette.mode === "light" ? "primary.main" : "primary.main",
    },
  })
);

function LinearProgressWithLabel(props: any) {
  const { selectedDirection } = useSelectedLanguage();

  return (
    <Grid container alignItems="center">
      <Grid item xs={8} ml="1rem">
        <BorderLinearProgress
          variant="determinate"
          {...props}
          direction={selectedDirection}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props?.value
        )}%`}</Typography>
      </Grid>
    </Grid>
  );
}

const IssueRow = (props: IssueEntity) => {
  const [expanded, setExpanded] = useState(false);
  const { printing } = usePrintStore();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (printing) setExpanded(true);
    else setExpanded(false);
  }, [printing]);

  const { name, label, management, risk, subIssues, description } = props;
  const { issues } = useContext(FiltersContext);
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  const labelStyles = {
    color: " #ff7b5f",
    border: "0.1rem solid #ff7b5f",
    "border-radius": "1.5rem",
    padding: "0.5rem",
    "white-space": "nowrap",
  };

  return (
    <Fragment>
      <Grid
        container
        onClick={handleExpandClick}
        sx={{
          cursor: "pointer",
          justifyContent: "start",
          alignItems: "center",
          padding: "1rem 1rem",
        }}
      >
        <Grid
          item
          xs={3}
          sx={{
            pl: "1rem",
          }}
        >
          <Typography variant="body2">{label || "-"}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Box width="min-content">
            {risk &&
              (selectedLanguage === "en" ? (
                <Typography style={{ ...labelStyles }}>
                  {Object.values(InvolvementLevelMap)[risk - 1]}
                </Typography>
              ) : (
                <Icon
                  name={"RED_" + Object.keys(InvolvementLevel)[risk - 1]}
                  size="5rem"
                  customHeight="1.5rem"
                />
              ))}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <LinearProgressWithLabel
            value={management ? (management - 1) * 25 : undefined}
          />
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ width: "100%", textAlign: "right" }}>
            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <Grid
          container
          paddingBottom="1rem"
          wrap={printing ? "nowrap" : "wrap"}
        >
          <Grid
            item
            lg={3}
            md={5}
            sm={6}
            xs={printing ? 3 : 12}
            sx={{ direction: "rtl" }}
            pb="1rem"
          >
            <ColorGrid {...props} />
          </Grid>
          <Grid
            item
            lg={5}
            md={7}
            sm={6}
            xs={printing ? 5 : 12}
            padding="3.3rem 0 1rem"
          >
            {issues
              .find((h: IssueEntity) => h.name === name)
              ?.subIssues?.map(
                (subIssueItem: TextValuePair<string>, i: number) => {
                  return (
                    <Grid key={i}>
                      <FormControlLabel
                        sx={{ padding: ".3rem", margin: "0" }}
                        label={subIssueItem.value}
                        control={
                          <Checkbox
                            icon={
                              subIssues?.some(
                                (subIssue: TextValuePair<string>) => {
                                  return subIssue.text === subIssueItem.text;
                                }
                              ) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <CancelOutlinedIcon />
                              )
                            }
                            color={
                              subIssues?.some(
                                (subIssue: TextValuePair<string>) =>
                                  subIssue.text === subIssueItem.text
                              )
                                ? "primary"
                                : "secondary"
                            }
                            disabled
                            //  sx={{ transform: "translateY(50%) scaleX(-1)" }}
                          />
                        }
                      />
                    </Grid>
                  );
                }
              )}
          </Grid>
          <Grid item lg={4} xs={printing ? 4 : 12}>
            {description && (
              <Box
                sx={{
                  background: "#EFF2F5",
                  padding: "1rem",
                  margin: "1rem",
                  borderRadius: "10px",
                }}
                mt={{ xs: "2rem", lg: "3.5rem" }}
              >
                <Typography variant="body2">{description}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Fragment>
  );
};

export default IssueRow;
